import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom"
// import 'bootstrap/dist/css/bootstrap.min.css';

import SubscriptionPage from "./pages/SubscriptionPage";
import NotFound from "./404/NotFound";

import "./index.css"
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";

import News from "./components/news/index";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<SubscriptionPage />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-details" element={<BlogDetails />} />
          <Route path="/press" element={<News />} />
          <Route path="/en" element={<Navigate to="/" />} />
          <Route path="/*" element={<NotFound />} />
          <Route path="/book-demo" element={<SubscriptionPage />} />
          <Route path="/referralmodal" element={<SubscriptionPage />} />
          <Route path="/casestudymodal" element={<SubscriptionPage />} />
          <Route path="/merchantmodal" element={<SubscriptionPage />} />
          <Route path="/merchant-info-form" element={<SubscriptionPage />} />
        </Routes>
      </Router>
    </>
  )
}

export default App
