import React, { useEffect, useState } from "react"
import FooterComponent from "../components/FooterComponent"
import { useNavigate } from "react-router-dom"
import Header from "../components/HeaderComponent"
import BlogList from "../components/Blog/BlogList"
import BlogInfo from "../components/Blog/BlogInfo"
import SocialMedia from "../components/Blog/SocialMedia"

const BlogDetails = () => {
  const navigate = useNavigate()
  const [blogData, setBlogData] = useState(null)
  let url = new URL(window.location.href)
  let hash = url.hash.replace("#", "")

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://restogptai.com/api/blogs?filters[slug][$eq]=${hash}`
        )
        const data = await response.json()
        setBlogData(data.data) // Assuming the data is under the "data" key
      } catch (error) {
        console.error("Error fetching blog data:", error)
      }
    }

    fetchData()
  }, [hash])
  const data = blogData?.[0]?.attributes?.components

  return (
    <div className="bg-[#F8F4E6]">
      <Header />
      <div className="bg-[#EAE4CE] rounded-bl-[30px]  md:rounded-bl-[140px] md:pb-[77px] pb-7 pt-[120px] md:pt-[190px] ">
        <div className=" md:container">
          <div className="px-2.5 md:px-0">
            <span
              className="text-[#667085] text-base leading-6 font-normal font-sfPro cursor-pointer"
              onClick={() => navigate("/")}
            >
              HOME /{" "}
            </span>
            <span
              className="text-[#667085] text-base leading-6 font-normal font-sfPro cursor-pointer"
              onClick={() => navigate("/blog")}
            >
              BLOG /{" "}
            </span>
            <span className="text-black text-base leading-6 font-normal font-sfPro">
              Article
            </span>
          </div>
          {data?.map((component, index) => {
            if (component?.__component === "widget-list.blog-details-header") {
              return (
                <div key={index} className="px-2.5 md:px-0">
                  <h1 className="font-medium md:text-[46px] text-2xl leading-normal font-sfPro mt-8">
                    {component?.title}
                  </h1>
                  <div className="flex items-center text-[#BE000A] mt-8 gap-5">
                    <p className=" text-base font-semibold leading-5 font-sfPro">
                      {component?.date}
                    </p>
                    <p className="text-base font-normal font-degularDisplay leading-5">
                      {component?.readtime}
                    </p>
                  </div>
                </div>
              )
            }
          })}
        </div>
      </div>

      <div className="md:grid md:grid-cols-6 grid-cols-none md:gap-[68px] gap-0 mt-[46px] lg:container h-full">
        {data?.map((component, index) => {
          if (component?.__component === "widget.blogdetails") {
            return <BlogInfo data={{ ...component }} key={index} />
          }
          if (component?.__component === "widget-list.social-media") {
            return <SocialMedia data={{ ...component }} key={index} />
          }
        })}
      </div>
      {data?.map((component, index) => {
        if (component?.__component === "widget-list.articles") {
          return <BlogList data={{ ...component }} key={index} />
        }
      })}
      <FooterComponent />
    </div>
  )
}

export default BlogDetails
