import React, { useState, useEffect } from "react";
import CloseImg from "../../images/Close.png";

const MerchantIframe = ({ openIframePopup, onClose, queryParams }) => {
  const handleClose = () => {
    onClose();
  };

  const handleIframeLoad = () => {};

  return (
    <div>
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 transition-opacity duration-300 ${
          openIframePopup ? "opacity-100" : "opacity-0 hidden"
        }`}
      >
        <div className="bg-white rounded-[30px] max-w-2xl min-w-[100%] sm:min-w-[390px] lg:min-w-[750px] max-h-[95%] relative z-50 md:overflow-y-auto overflow-y-scroll">
          <button
            className="absolute top-2.5 right-2.5 bg-transparent text-black border-none p-2.5 cursor-pointer z-50"
            onClick={handleClose}
          >
            <img src={CloseImg} alt="CloseImg" />
          </button>

          <iframe
            src={queryParams}
            className="w-full h-full border-none rounded"
            id="inline-UNFtd7cQWfcQTXrO3PwF"
            data-layout="{'id':'INLINE'}"
            data-trigger-type="alwaysShow"
            data-trigger-value=""
            data-activation-type="alwaysActivated"
            data-activation-value=""
            data-deactivation-type="neverDeactivate"
            data-deactivation-value=""
            data-form-name="Merchant information (Onboarding) form"
            data-height="727"
            data-layout-iframe-id="inline-UNFtd7cQWfcQTXrO3PwF"
            data-form-id="UNFtd7cQWfcQTXrO3PwF"
            title="Merchant information (Onboarding) form"
          ></iframe>
        </div>
      </div>

      <style jsx>{`
        .spinner-loader-case {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border: 6px solid rgba(0, 0, 0, 0.1);
          border-top: 6px solid #000;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          animation: spin 1s linear infinite;
        }
        @keyframes spin {
          0% {
            transform: translate(-50%, -50%) rotate(0deg);
          }
          100% {
            transform: translate(-50%, -50%) rotate(360deg);
          }
        }
      `}</style>
    </div>
  );
};

export default MerchantIframe;
