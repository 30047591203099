import React, { useState, useEffect } from "react"

import Logo from "../../images/logo-modal.png"
import CloseImg from "../../images/Close.png"
import { eventsHandler } from "../../utils/ga/clickEventHandler"

const CaseStudyModal = ({ open, onClose }) => {
  const handleClose = () => {
    const storageData = JSON.parse(localStorage?.getItem("_ud"))
    eventsHandler(storageData, "customer_receive_case_study")
    onClose()
  }
  return (
    <>
      {open && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-[#F9F7F0] rounded-[15px] max-w-xl min-w-[90%] sm:min-w-[350px] lg:min-w-[200px] max-h-[95%] overflow-auto relative z-50 custom-scrollbar">
            <button
              className="absolute top-2.5 right-2.5 bg-transparent text-black border-none p-2.5 cursor-pointer z-50"
              onClick={handleClose}
            >
              <img src={CloseImg} alt="CloseImg" />
            </button>
            {/* {isLoading && <div className="spinner-loader z-50"></div>} */}
            <div className="p-10">
              <div className="flex items-center justify-center">
                <img src={Logo} alt="Logo" />
              </div>
              <div className="mt-10 mb-10">
                <h4 className="text-center font-sfPro font-bold text-[#740C00] text-[34px] sm:text-[38px]">
                  Thank You
                </h4>
                <p className="text-center font-sfPro text-[#000] text-[22px] sm:text-[26px] px-0 sm:px-10 pt-6">
                  Your form has been submitted and you will shortly receive the
                  case study in your email.
                </p>
              </div>
              <div className="flex items-center justify-center">
                <button
                  className="font-sfPro font-semibold bg-[#061737] text-white py-3 px-[20px] sm:px-[65px] text-[18px] xl:text-[24px] rounded-full sm:text-[24px] mx-auto text-center uppercase"
                  onClick={handleClose}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CaseStudyModal
