import React, { useEffect, useState } from "react"
import FooterComponent from "../components/FooterComponent/index"
import { useNavigate } from "react-router-dom"
import Header from "../components/HeaderComponent"
import BlogFilters from "../components/Blog/BlogFilters"
import BlogNavHeader from "../components/Blog/BlogNavHeader"

const Blog = () => {
  const navigate = useNavigate()
  const [blogData, setBlogData] = useState(null)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://restogptai.com/api/blogs?filters[slug][$eq]=blog"
        )
        const data = await response.json()
        setBlogData(data.data) // Assuming the data is under the "data" key
      } catch (error) {
        console.error("Error fetching blog data:", error)
      }
    }

    fetchData()
  }, [])
  const data = blogData?.[0]?.attributes?.components

  return (
    <div className="bg-[#F8F4E6]">
      <div className="px-0 md:px-8 xl:px-0">
        <div id="TOP"></div>
        <Header />
        <div className="bg-[#EAE4CE] rounded-bl-[30px] md:rounded-bl-[140px] pt-[120px] md:pt-[190px]  ">
          {/* //md:pt-48 xl:pt-40 */}
          <div className="md:container">
            <div className="md:px-0 px-2.5">
              <span
                className="text-[#667085] text-base leading-6 font-normal font-sfPro cursor-pointer"
                onClick={() => navigate("/")}
              >
                HOME /{" "}
              </span>
              <span className="text-black text-base leading-6 font-normal font-sfPro">
                BLOG
              </span>
            </div>

            {data?.map((component, index) => {
              if (component?.__component === "widget-list.blognav") {
                return <BlogNavHeader {...component} key={index} />
              }
            })}
          </div>
        </div>
        {data?.map((component, index) => {
          if (component?.__component === "widget-list.bloglist") {
            return <BlogFilters data={{ ...component }} key={index} />
          }
        })}
        <FooterComponent />
      </div>
    </div>
  )
}

export default Blog
