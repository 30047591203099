import React, { useState, useEffect } from "react"
import CloseImg from "../../images/Close.png"
import { eventsHandler } from "../../utils/ga/clickEventHandler"

const IframePopupComponent = ({ openIframePopup, onClose, queryParams }) => {
  const handleClose = () => {
    const storageData = JSON.parse(localStorage?.getItem("_ud"))
    onClose()
    eventsHandler(storageData, "customer_data_submit")
  }

  const handleIframeLoad = () => {}

  return (
    <div>
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ${
          openIframePopup ? "opacity-100" : "opacity-0 hidden"
        }`}
      >
        <div className="bg-white rounded-[10px] max-w-xl min-w-[90%] sm:min-w-[390px] lg:min-w-[500px] max-h-[95%] overflow-auto relative z-50 custom-scrollbar">
          <button
            className="absolute top-2.5 right-2.5 bg-transparent text-black border-none p-2.5 cursor-pointer z-50"
            onClick={handleClose}
          >
            <img src={CloseImg} alt="CloseImg" />
          </button>
          {/* {isLoading && <div className="spinner-loader z-50"></div>} */}
          <iframe
            src={queryParams}
            className="w-full h-[500px] lg:h-[600px] border-none rounded z-40 overflow-auto custom-scrollbar"
            scrolling="no"
            id="4dyJhjVr4Ry5TwY6RzsP_1721633568237"
            title="Book a demo form"
          ></iframe>
        </div>
      </div>

      <style jsx>{`
        .spinner-loader {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border: 6px solid rgba(0, 0, 0, 0.1);
          border-top: 6px solid #000;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          animation: spin 1s linear infinite;
        }
        @keyframes spin {
          0% {
            transform: translate(-50%, -50%) rotate(0deg);
          }
          100% {
            transform: translate(-50%, -50%) rotate(360deg);
          }
        }

        .custom-scrollbar::-webkit-scrollbar {
          width: 8px;
        }

        .custom-scrollbar::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 10px;
        }

        .custom-scrollbar::-webkit-scrollbar-thumb {
          background: #eae4ce;
          border-radius: 30px;
        }

        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
        /* Firefox scrollbar styles */
        .custom-scrollbar {
          scrollbar-width: thin;
          scrollbar-color: #eae4ce #fff;
        }
        .custom-scrollbar::-moz-scrollbar {
          width: 12px;
        }

        .custom-scrollbar::-moz-scrollbar-track {
          background: #f1f1f1;
          border-radius: 10px;
        }

        .custom-scrollbar::-moz-scrollbar-thumb {
          background: #eae4ce;
          border-radius: 10px;
        }

        .custom-scrollbar::-moz-scrollbar-thumb:hover {
          background: #555;
        }
      `}</style>
    </div>
  )
}

export default IframePopupComponent
