import React, { useRef, useState } from "react"
import MenuOpener from "../../images/mobile_menu_opener.png"
import Logo from "../../images/slogo.svg"
import Iframe from "../../components/Iframe/index"

const Header = () => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const [queryParams, setQueryParams] = useState("")
  const [openIframePopup, setOpenIframePopup] = useState(false)
  const [queryParamsWithOutHash, setQueryParamsWithOutHash] = useState("")
  const topRef = useRef(null)
  const checkParamsInUrl = (url) => {
    const hashIndex = url.indexOf("#")
    if (hashIndex !== -1) {
      const hashPart = url.substring(hashIndex + 1)
      const queryIndex = hashPart.indexOf("?")
      if (queryIndex !== -1) {
        return hashPart.substring(queryIndex)
      }
    }
    return ""
  }
  const loadIframe = () => {
    // Append the script tag to the document body when the popup is open
    const script = document.createElement("script")
    script.src = "https://link.msgsndr.com/js/form_embed.js"
    script.async = true
    document.body.appendChild(script)
  }
  const checkParamsInUrlWithOutHash = (url) => {
    const pathSegment = "/merchant-info-form"

    if (url.includes(pathSegment)) {
      // If there's a query string, extract it
      const queryIndex = url.indexOf("?")
      if (queryIndex !== -1) {
        return url.substring(queryIndex) // This includes the leading "?"
      }
    }

    // If the path segment is not found or there is no query string, return an empty string
    return ""
  }

  const checkParamsInUrlWithOutHashBookADemo = (url) => {
    // Define the specific path segment to look for
    const pathSegment = "/book-demo"

    // Check if the URL contains the specific path segment
    if (url.includes(pathSegment)) {
      // If there's a query string, extract it
      const queryIndex = url.indexOf("?")
      if (queryIndex !== -1) {
        return url.substring(queryIndex) // This includes the leading "?"
      }
    }

    // If the path segment is not found or there is no query string, return an empty string
    return ""
  }
  React.useEffect(() => {
    loadIframe()

    let iframeUrl =
      "https://api.leadconnectorhq.com/widget/booking/4dyJhjVr4Ry5TwY6RzsP"

    let iframeUrlMerchant =
      "https://api.leadconnectorhq.com/widget/form/UNFtd7cQWfcQTXrO3PwF"

    const hash = window.location.hash
    const url = window.location.href

    let params = checkParamsInUrl(url)
    let paramsWithOutHash = checkParamsInUrlWithOutHash(url)
    let paramsBookADemo = checkParamsInUrlWithOutHashBookADemo(url)

    let fullUrl = iframeUrl + params
    let urlForIframe = params ? fullUrl : iframeUrl

    let fullUrlWithOutHash = iframeUrlMerchant + paramsWithOutHash
    let urlForIframeWithOutHash = paramsWithOutHash
      ? fullUrlWithOutHash
      : iframeUrlMerchant

    setQueryParams(urlForIframe)
    setQueryParamsWithOutHash(urlForIframeWithOutHash)

    const pathParts = window.location.pathname.split("/")
    const referralModalPart = pathParts[pathParts.length - 1]

    if (!hash && topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" })
    }

    if (params) {
      setOpenIframePopup(true)
    } else {
      setOpenIframePopup(false)
    }

    if (referralModalPart === "book-demo" || paramsBookADemo) {
      setOpenIframePopup(true)
    } else {
      setOpenIframePopup(false)
    }
  }, [])
  const handleCloseIframePopup = () => {
    setOpenIframePopup(false)
  }
  const handleOpenIframe = () => {
    setOpenIframePopup(true)
  }
  return (
    <div>
      <div id="TOP" ref={topRef}></div>
      <div
        className="w-full bg-[#EAE4CE] fixed top-0 z-50 px-6 lg:px-0 right-0"
        id="navbar"
      >
        <div className="w-full md:container">
          <nav className="pt-8">
            <div className="py-2 flex flex-col xl:flex-row justify-between items-start">
              {/* Logo */}
              <div className="text-2xl font-bold w-full xl:w-[37%] flex justify-between">
                <img src={Logo} alt="Logo" className="w-[70%] 2xl:w-[80%]" width={426} height={104} />
                <div className="xl:hidden">
                  <img
                    src={MenuOpener}
                    alt="menu opener"
                    className="w-12 sm:w-16 md:w-20 lg:w-28"
                    onClick={() => setOpenMobileMenu(!openMobileMenu)}
                  />
                </div>
              </div>

              {/* Menu */}
              <div
                className={`w-full xl:w-[63%] mt-6 xl:flex transition-all duration-300 ease-in-out ${
                  openMobileMenu ? "" : "hidden"
                }`}
              >
                <div className="w-full flex flex-col xl:flex-row bg-[#FFBD00] justify-between items-center gap-1 rounded-3xl xl:rounded-full">
                  <a
                    href="/#HOWITWORKS"
                    className="relative py-3 sm:py-6 xl:py-0 pl-4 xl:pl-8 pr-3 text-black-900 font-semibold text-xl sm:text-2xl xl:text-[12px] 2xl:text-[17px] text-center flex-grow dot-before"
                    //   onClick={() => handleScroll(howItWorkRef, "HOWITWORKS")}
                  >
                    HOW IT WORKS
                  </a>
                  {/* <span className="hidden xl:inline">|</span> */}
                  <a
                    href="/#WHYSUBSCRIPTIONS"
                    className="relative py-3 sm:py-6 xl:py-0 px-3 text-black-900 font-semibold text-xl sm:text-2xl xl:text-[12px] 2xl:text-[17px] text-center flex-grow dot-before"
                    //   onClick={() =>
                    //     handleScroll(whySubscriptionRef, "WHYSUBSCRIPTIONS")
                    //   }
                  >
                    WHY SUBSCRIPTIONS
                  </a>
                  {/* <span className="hidden xl:inline">|</span> */}
                  <a
                    href="/#BENEFITS"
                    className="relative py-3 sm:py-6 xl:py-0 px-3 text-black-900 font-semibold text-xl sm:text-2xl xl:text-[12px] 2xl:text-[17px] text-center flex-grow dot-before"
                    //   onClick={() => handleScroll(benefitRef, "BENEFITS")}
                  >
                    BENEFITS
                  </a>
                  {/* <span className="hidden xl:inline">|</span> */}
                  <a
                    href="/#FAQ"
                    className="py-3 sm:py-6 xl:py-0 px-3 text-black-900 font-semibold text-xl sm:text-2xl xl:text-[12px] 2xl:text-[17px] text-center flex-grow dot-before"
                    //   onClick={() => handleScroll(faqRef, "FAQ")}
                  >
                    FAQ
                  </a>
                  <a
                    href="#BOOKADEMO"
                    onClick={handleOpenIframe}
                    className="text-white py-3 sm:py-6 xl:py-2 bg-black font-semibold px-[40px] text-[24px] sm:text-[26px] xl:text-[22px] rounded-3xl xl:rounded-full w-full xl:w-64 text-center"
                  >
                    Book a demo
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <Iframe
        openIframePopup={openIframePopup}
        onClose={handleCloseIframePopup}
        queryParams={queryParams}
      />
    </div>
  )
}

export default Header
